import { usePathname } from 'next/navigation'
import { useEffect } from 'react'
import { create } from 'zustand'

interface PrevPathType {
  prevPath: string
  currentPath: string
}

const prevPathStore = create<PrevPathType>(() => ({
  prevPath: '',
  currentPath: '',
}))

export const usePrevPath = () => {
  return prevPathStore((state) => state)
}

export const usePrevPathInitializer = () => {
  const currentPath = usePathname()
  const { currentPath: prevPath } = usePrevPath()

  useEffect(() => {
    if (currentPath !== prevPath) {
      prevPathStore.setState(() => ({
        currentPath,
        prevPath,
      }))
    }
  }, [currentPath, prevPath])
}
