import { useQuery } from '@apollo/client'

import { useNotification } from '~/src/components/shared/Notification/hooks/useNotification'
import { GET_NOTIFICATIONS_FOR_USER } from '~/src/components/shared/Notification/queries'
import {
  GetNotificationsForUserQuery,
  GetNotificationsForUserQueryVariables,
} from '~/src/components/shared/Notification/__generated_types__/queries.gql'

const useGetNotificationsForUser = (
  timeFloor: string,
  shouldQueryForNotifications: boolean
): void => {
  const notificationContext = useNotification()

  useQuery<GetNotificationsForUserQuery, GetNotificationsForUserQueryVariables>(
    GET_NOTIFICATIONS_FOR_USER,
    {
      variables: { timeFloor },
      skip: !shouldQueryForNotifications,
      onCompleted: (data) => {
        notificationContext?.update([...data.getNotificationsForUser])
      },
    }
  )
}

export default useGetNotificationsForUser
