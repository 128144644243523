import { FC } from 'react'

import { IconPropsType } from '~/src/components/generic/PhosphorIcons/types'

export type ItemMenu = {
  iconComponent: FC<IconPropsType>
  label: string
  action: ActionEnum
}

export enum ActionEnum {
  ReportIssue = 'REPORT_AN_ISSUE',
  Settings = 'SETTINGS',
  Logout = 'LOG_OUT',
}
