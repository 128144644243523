import { diffInSeconds } from '~/helpers/date'
import { endOfDay, startOfDay } from '~/helpers/time'

const getLegTotalTripTime = (
  endTime: string | undefined | null,
  startTime: string | undefined | null,
  estimatedTripTime: number
): number => {
  if (endTime && startTime) {
    return diffInSeconds(new Date(endTime), new Date(startTime))
  }

  return estimatedTripTime
}

export { getLegTotalTripTime }

/**
 * helper function to get the date range for the current week
 */
export const getWeekDateRange = (): { start: string; end: string } => {
  const today = new Date()
  const startDateTime = startOfDay(today)
  const endDateTime = endOfDay(today)
  const startDate = new Date(startDateTime.setDate(startDateTime.getDate() - 1))
  const endDate = new Date(endDateTime.setDate(endDateTime.getDate() + 30))

  return { start: startDate.toISOString(), end: endDate.toISOString() }
}
