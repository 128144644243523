import { gql } from '@apollo/client'

const UPDATE_USER_SETTINGS = gql`
  mutation UpdateUserSettings($input: UserSettingsUpdate!) {
    updateUserSettings(input: $input) {
      ... on UserSettings {
        updatedAt
      }
      ... on TooManyFavoriteCostCenters {
        __typename
      }
      ... on ObjectDoesNotExist {
        __typename
      }
    }
  }
`
export { UPDATE_USER_SETTINGS }
