export enum Tag {
  BUG = 'bug',
  FEATURE = 'feature',
  FEEDBACK = 'feedback',
}

export type Option = {
  value: string
  tag?: Tag
}

export type RequestStatus = null | 'success' | 'error'
