import { FC } from 'react'
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react'
import { twMerge } from 'tailwind-merge'

import CaretDownIcon from '~/src/components/generic/PhosphorIcons/CaretDownIcon'
import Spinner from '~/src/components/generic/Spinner'

export type Option = {
  value: string
  label?: string
}
type Props = {
  options: Array<Option>
  selectedOption: Option
  onOptionChange: (option: Option) => void
  className?: string
  buttonClassName?: string
  caretClassName?: string
  containerClassName?: string
  optionsClassName?: string
  disabled?: boolean
  isLoading?: boolean
}

// We are adding putting a label almost every time we use this component
// We should add label internally to it:
// https://app.asana.com/0/1205637448622252/1205964578193606
const SelectDropdown: FC<Props> = ({
  options,
  selectedOption,
  onOptionChange,
  className,
  buttonClassName,
  caretClassName,
  containerClassName,
  optionsClassName,
  disabled = false,
  isLoading = false,
}) => {
  return (
    <div className={className}>
      <Listbox value={selectedOption} onChange={onOptionChange} disabled={disabled}>
        <div className={twMerge('relative mt-1', containerClassName)}>
          <ListboxButton
            className={twMerge(
              'relative w-full rounded-md border border-gray-100 bg-white py-2 pl-3 pr-10 text-left focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600',
              buttonClassName
            )}
          >
            <span className="block truncate">
              {selectedOption.label ?? selectedOption.value}
            </span>
            <span
              className={twMerge(
                'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2',
                caretClassName
              )}
            >
              <CaretDownIcon size={16} weight="regular" />
            </span>
          </ListboxButton>

          <ListboxOptions
            className={twMerge(
              'absolute z-50 mt-0.5 max-h-60 w-full overflow-auto rounded-md bg-white text-sm shadow-lg ring-1 ring-black ring-opacity-5',
              optionsClassName
            )}
          >
            {isLoading && (
              <div
                className="flex h-24 items-center justify-center"
                data-testid="spinner"
              >
                <Spinner size={25} />
              </div>
            )}
            {options.map((option, optionIdx) => (
              <ListboxOption
                key={optionIdx}
                className={({ focus }) =>
                  `relative cursor-pointer select-none px-2 py-2 pr-4 ${
                    focus ? 'bg-gray-50 text-gray-900' : 'text-gray-900'
                  }`
                }
                value={option}
              >
                {({ selected }) => (
                  <>
                    <div
                      className={`block truncate ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {option.label ?? option.value}
                    </div>
                  </>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      </Listbox>
    </div>
  )
}

export default SelectDropdown
