'use client'
import { notFound, redirect, usePathname } from 'next/navigation'
import React, { FC, PropsWithChildren, useState, useCallback, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'
import { useFlags } from 'launchdarkly-react-client-sdk'

import NavBar from '~/src/components/shared/NavBar'

import {
  getAvailablePages,
  getCurrentPage,
  getOperatorHomePagePath,
} from '~/app/helpers'
import { isRadiusUser } from '~/helpers/auth'

import { useAuthentication } from 'hooks/useAuthentication'
import useDeviceDetection from '~/hooks/useDeviceDetection'
import { usePrevPathInitializer } from 'hooks/usePrevPathInitializer'

import MobileNavBar from 'src/components/shared/NavBar/MobileNavBar'
import { AuthenticatedUserContext } from '~/providers/AuthenticatedUserContext'
import Sidebar from '~/src/components/Sidebar'
import withApollo from '~/hoc/withApollo'
import Settings from '~/src/components/Settings'
import { useScreenTimeTracking } from '~/app/(authenticated)/useScreenTimeTracking'
import useSprig from '~/app/(authenticated)/useSprig'
import { CustomerLoadBasePath, ShipmentBasePath } from '~/constants/urls'

import { CustomerType, UserRole } from '~/__generated_types__/globalTypes'

const AuthenticatedLayout: FC<PropsWithChildren> = ({ children }) => {
  const authentication = useAuthentication()
  usePrevPathInitializer()

  const [user] = authentication
  const device = useDeviceDetection()

  const { startSession } = useScreenTimeTracking()

  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const { showHomePageSetting } = useFlags()

  const handleCloseSettings = useCallback(() => {
    setIsSettingsOpen(false)
  }, [])

  const handleOpenSettings = useCallback(() => {
    setIsSettingsOpen(true)
  }, [])

  useSprig(
    user?.role,
    user?.emails[0].email,
    process.env.NEXT_PUBLIC_SPRIG_ENVIRONMENT_ID
  )

  useEffect(() => {
    user && startSession()

    if (user) {
      // for testing purposes
      !process.env.NEXT_PUBLIC_SPRIG_ENVIRONMENT_ID &&
        console.error('Call from layout: Sprig environment ID is not set')

      // track user attributes
      window.Sprig('setUserId', user.uuid)
      window.Sprig('setAttributes', {
        USER_ROLE: user.role,
        USER_EMAIL: user.emails[0].email,
      })
    }
  }, [startSession, user])

  const pathname = usePathname()

  if (!user) return null

  const availablePages = getAvailablePages(user.role, user.customer?.type)
  const currentPage = getCurrentPage(pathname, availablePages)

  if (!currentPage) {
    switch (user.role) {
      case UserRole.Operator:
      case UserRole.Sysadmin:
        showHomePageSetting
          ? redirect(getOperatorHomePagePath(user.settings.homePage))
          : redirect('/visibility')
      case UserRole.Customer:
        user.customer?.type && user.customer.type === CustomerType.Tm
          ? redirect(ShipmentBasePath)
          : redirect(CustomerLoadBasePath)
      default:
        notFound()
    }
  }

  return (
    <AuthenticatedUserContext.Provider value={authentication}>
      <div
        className={twMerge(
          device.isDesktop
            ? 'grid h-screen w-screen grid-flow-col grid-cols-authenticatedLayout grid-rows-authenticatedLayout'
            : 'min-h-screen'
        )}
      >
        {device.isDesktop && (
          <Sidebar pages={availablePages} onOpenSettings={handleOpenSettings} />
        )}
        {currentPage?.title &&
          (device.isMobile && isRadiusUser(user) ? (
            <MobileNavBar title="Mobile Dispatch" onOpenSettings={handleOpenSettings} />
          ) : (
            <NavBar
              title={currentPage.title}
              logo={currentPage.logo}
              onOpenSettings={handleOpenSettings}
            />
          ))}
        {children}
      </div>
      {isRadiusUser(user) && isSettingsOpen && (
        <Settings
          showHomePageSetting={showHomePageSetting && device.isDesktop}
          onCloseDialog={handleCloseSettings}
        />
      )}
    </AuthenticatedUserContext.Provider>
  )
}
export default withApollo()(AuthenticatedLayout)
