import { FC, PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

import XIcon from '~/src/components/generic/PhosphorIcons/XIcon'
import IconButton from '~/src/components/generic/IconButton'

type Props = {
  content?: string
  className?: string
  ariaLabel?: string
  onClose?: () => void
}

/**
 * SearchPill component to render content of applied search silter
 * @param param0
 * @returns
 */

const SearchPill: FC<PropsWithChildren<Props>> = ({
  content,
  children,
  className,
  ariaLabel,
  onClose,
}) => {
  return (
    <div
      className={twMerge(
        'flex items-center space-x-2 rounded border border-[#E8E8EA] px-2 py-1 text-sm font-medium',
        className
      )}
      data-testid="search-pill"
    >
      {children}
      <div>{content}</div>
      {onClose && (
        <IconButton variant="ghost" onClick={onClose} aria-label={ariaLabel}>
          <XIcon size="18" color="#9DA3AE" />
        </IconButton>
      )}
    </div>
  )
}

export default SearchPill
