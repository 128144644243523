import React from 'react'
import { twMerge } from 'tailwind-merge'

type Option<T> = {
  value: T
  label: string
}

export type Options<T> = Array<Option<T>>

type RadioProps<T extends string> = {
  className?: string
  label?: string
  name: string
  onSelect: (value: T) => void
  optionClassName?: string
  options: Options<T>
  value: T
}

const RadioGroup = <T extends string>({
  className,
  label,
  name,
  onSelect,
  optionClassName,
  options,
  value,
}: RadioProps<T>): JSX.Element => {
  return (
    <div className={twMerge('flex justify-between', className)}>
      {label && <label>{label}</label>}
      {options.map((item, idx) => {
        return (
          <div
            className={twMerge(
              'flex space-x-2 align-middle text-label accent-blue-500',
              optionClassName
            )}
            key={`${name}-${idx}`}
          >
            <input
              checked={value === item.value}
              data-testid={item.value}
              id={item.value}
              name={name}
              onChange={() => onSelect(item.value)}
              type="radio"
            />
            <label htmlFor={item.value}>{item.label}</label>
          </div>
        )
      })}
    </div>
  )
}

export default RadioGroup
