import { useEffect, useRef } from 'react'

import { MixpanelEvent, track } from '~/clients/mixpanelClient'

export const useTabTracking = (tab: string) => {
  const lastTrackedTabRef = useRef<string | null>(null)

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (lastTrackedTabRef.current !== tab) {
        track(MixpanelEvent.RadiusTabView, { tab })
        lastTrackedTabRef.current = tab
      }
    }, 5000)

    return () => clearTimeout(timeout)
  }, [tab])

  return null
}
