import { MouseEvent, PropsWithChildren as PWC, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  sizeClasses,
  variantClassesV2,
} from '~/src/components/generic/Button/constants'
import { Size } from '~/src/components/generic/Button/types'
import Spinner from '~/src/components/generic/Spinner'

type CommonProps = {
  className?: string
  loading?: boolean
  title?: string
  disabled?: boolean
  startIcon?: React.ReactNode
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  'aria-label'?: string
}

type VariantProps =
  | { variant: 'primary' | 'secondary'; size: Size }
  | { variant: 'link'; size?: never }
  | { variant?: never; size?: never }

type Props = CommonProps & VariantProps

const Button = forwardRef<HTMLButtonElement, PWC<Props>>(
  (
    {
      children,
      loading,
      onClick,
      title,
      disabled,
      className,
      variant,
      size,
      'aria-label': ariaLabel,
      startIcon,
    },
    ref
  ) => {
    if ((variant === 'primary' || variant === 'secondary') && !size) {
      console.warn(`Variant "${variant}" requires the "size" prop to be provided.`)
    }

    if (variant === 'link' && size) {
      console.warn(`Variant "link" does not accept the "size" prop.`)
    }

    const childrenWithIcon = startIcon ? (
      <div
        className="flex items-center justify-center gap-x-2"
        data-testid="children-start-icon"
      >
        {startIcon}
        {children}
      </div>
    ) : (
      children
    )

    return (
      <button
        disabled={disabled}
        className={twMerge(
          'cursor-pointer rounded px-5 py-2 text-center',
          loading ? 'relative' : '',
          variant && variantClassesV2[variant],
          variant !== 'link' && size && sizeClasses[size],
          className
        )}
        onClick={onClick}
        title={title}
        aria-label={ariaLabel}
        ref={ref}
      >
        <div className={loading ? 'invisible' : 'visible'}>{childrenWithIcon}</div>
        <div
          data-testid="spinner"
          className={twMerge(
            'absolute left-0 top-0 h-full w-full items-center justify-center',
            loading ? 'flex' : 'hidden'
          )}
        >
          <Spinner color="#fff" size={32} />
        </div>
      </button>
    )
  }
)

Button.displayName = 'Button'
export default Button
