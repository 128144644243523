import Button from '~/src/components/generic/Button'
import MagnifyingGlassIcon from '~/src/components/generic/PhosphorIcons/MagnifyingGlassIcon'
import XIcon from '~/src/components/generic/PhosphorIcons/XIcon'

import colors from '~/config/colorsScheme'

type Props = {
  showSearchIcon: boolean
  searchString: string
  inputEl: React.RefObject<HTMLInputElement>
  handleClearSearchString: () => void
}

const SearchIcon = ({
  showSearchIcon,
  searchString,
  inputEl,
  handleClearSearchString,
}: Props) => {
  if (!showSearchIcon) return null

  return (
    <div className="absolute right-2 top-1/2 flex -translate-y-1/2">
      <div className="relative flex items-center justify-center text-gray-400 group-hover:text-gray-500">
        {searchString === '' && (
          <Button
            onClick={() => inputEl.current?.focus()}
            aria-label="Focus search input"
            className="h-full pr-1"
          >
            <MagnifyingGlassIcon size={20} />
          </Button>
        )}

        {searchString !== '' && (
          <Button
            className="aligns-center pr-1"
            onClick={handleClearSearchString}
            aria-label="Clear search"
          >
            <XIcon size={20} color={colors.gray[400]} />
          </Button>
        )}
      </div>
    </div>
  )
}

export default SearchIcon
