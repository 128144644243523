import { useEffect, useState } from 'react'

const useSprig = (
  userRole: string | undefined,
  userEmail: string | undefined,
  envValue: string | undefined
) => {
  const [Sprig, setSprig] = useState<unknown>(null)

  useEffect(() => {
    const loadSprig = async () => {
      //for testing purposes
      !envValue && console.error('Sprig environment passed from layout is not defined')
      !process.env.NEXT_PUBLIC_SPRIG_ENVIRONMENT_ID &&
        console.error(
          'Sprig environment ID is not defined in the environment variables'
        )

      if (!process.env.NEXT_PUBLIC_SPRIG_ENVIRONMENT_ID) return

      try {
        const { sprig } = await import('@sprig-technologies/sprig-browser')
        const sprigInit = sprig.configure({
          environmentId: process.env.NEXT_PUBLIC_SPRIG_ENVIRONMENT_ID,
        })
        if (userEmail) {
          // once page will be loaded, we will track the event and show survey to the user

          sprigInit('track', 'App Load', {
            user_email: userEmail,
            user_role: userRole,
          })
        }
        setSprig(sprigInit)
      } catch (error) {
        console.error('Failed to load the Sprig module', error)
      }
    }

    loadSprig()
  }, [userRole, userEmail, envValue])

  return Sprig
}

export default useSprig
