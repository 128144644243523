import Link from 'next/link'
import { FC, memo, MouseEventHandler, PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
  isSelected?: boolean
  onClick?: MouseEventHandler<HTMLAnchorElement>
  path?: string
  className?: string
  selectedClassName?: string
  'aria-label'?: string
}
const Tab: FC<PropsWithChildren<Props>> = ({
  isSelected = false,
  onClick,
  path = '',
  children,
  className,
  selectedClassName = 'border-l-4  border-ryder-red-secondary bg-gray-700',
  'aria-label': ariaLabel,
}) => {
  return (
    <Link
      className={twMerge(
        'flex w-full cursor-pointer items-center justify-center py-2 hover:bg-ryder-red-primary',
        className,
        isSelected && selectedClassName
      )}
      role="tab"
      tabIndex={0}
      aria-label={ariaLabel}
      href={path}
      onClick={onClick}
    >
      {children}
    </Link>
  )
}

export default memo(Tab)
