import { FC } from 'react'
import { twMerge } from 'tailwind-merge'

import SelectDropdown from '~/src/components/generic/SelectDropdown'

import { Option } from '~/src/components/ReportIssue/types'

type Props = {
  options: Array<Option>
  selectedOption: Option
  isError: boolean
  validationError: string
  onOptionChange: (value: Option) => void
  handleDescriptionChange: (value: string) => void
  handleTitleChange: (value: string) => void
}

const CreateTaskStep: FC<Props> = ({
  options,
  selectedOption,
  isError,
  validationError,
  onOptionChange,
  handleDescriptionChange,
  handleTitleChange,
}) => {
  return (
    <div className="space-y-4">
      <div className="flex flex-col space-y-1">
        <div className="font-medium">Type of feedback</div>
        <SelectDropdown
          options={options}
          selectedOption={selectedOption}
          onOptionChange={onOptionChange}
          className="text-xm"
        />
      </div>

      <div className="flex flex-col space-y-1">
        <label htmlFor="title" className="font-medium">
          Title
        </label>
        <input
          id="title"
          className={twMerge(
            'h-10 appearance-none rounded-md border border-gray-100 p-2 text-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm',
            validationError ? 'border-red-600' : ''
          )}
          type="text"
          placeholder="Enter a title"
          onChange={(e) => handleTitleChange(e.target.value)}
        />
        <div className="text-xs text-red-600">{validationError}</div>
      </div>

      <div className="flex flex-col space-y-1">
        <label htmlFor="description" className="font-medium">
          Description
        </label>
        <textarea
          id="description"
          className="h-40 w-full resize-none appearance-none rounded-md border border-gray-100 p-2 text-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm"
          placeholder="Enter your description"
          onChange={(e) => handleDescriptionChange(e.target.value)}
        ></textarea>
      </div>

      {isError && (
        <div className="text-red-600">Something went wrong. Please try again</div>
      )}
    </div>
  )
}

export default CreateTaskStep
