import { FC, PropsWithChildren as PWC, useCallback, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { useRouter } from 'next/navigation'
import { gql } from '@apollo/client'
import { useFlags } from 'launchdarkly-react-client-sdk'

import MobileSettingsMenu from '~/src/components/shared/NavBar/components/MobileSettingsMenu'
import { ActionEnum } from '~/src/components/shared/NavBar/types'
import UserProfileIcon from '~/src/components/shared/UserProfileIcon'

import { isRadiusUser } from '~/helpers/auth'

import { useAuthenticatedUser } from '~/hooks/useAuthentication'

import ReportIssue from '~/src/components/ReportIssue'
import { MixpanelEvent, resetMixpanel, track } from '~/clients/mixpanelClient'
import { useTabTracking } from '~/app/(authenticated)/useTabTracking'

//TODO: move it to separate file
export const LOGOUT = gql`
  mutation Logout {
    logout {
      id
    }
  }
`
type Props = {
  onOpenSettings: () => void
  title?: string
  className?: string
}
const MobileNavBar: FC<PWC<Props>> = ({
  title,
  children,
  className,
  onOpenSettings,
}) => {
  const [openReportIssue, setOpenReportIssue] = useState(false)
  const [user, logout] = useAuthenticatedUser()
  useTabTracking('Mobile Dispatch')

  const { showUserConfig } = useFlags()
  const shouldShowUserConfig = showUserConfig && isRadiusUser(user)

  const router = useRouter()

  const handleReportIssueClose = useCallback(() => {
    setOpenReportIssue(!openReportIssue)
  }, [openReportIssue])

  const handleOptionSelect = useCallback(
    (action: ActionEnum) => {
      switch (action) {
        case ActionEnum.ReportIssue:
          setOpenReportIssue(!openReportIssue)
          break
        case ActionEnum.Settings:
          onOpenSettings?.()
          break
        case ActionEnum.Logout:
          logout({
            onCompleted: (data) => {
              track(MixpanelEvent.UserLogout, { userId: data.logout.id })
              resetMixpanel()
              router.push('/login')
            },
          })
          break
        default:
          break
      }
    },
    [logout, onOpenSettings, openReportIssue, router]
  )

  return (
    <div
      className={twMerge(
        'flex h-[60px] w-full items-center justify-between border-b border-solid border-gray-75 bg-white px-4',
        className
      )}
      data-testid="navbar"
    >
      <div className="flex items-center">
        {title && <div className="ml-1 text-sm font-bold text-gray-800">{title}</div>}
        {children}
      </div>

      <div className="flex flex-row">
        {shouldShowUserConfig ? (
          <div className="flex gap-4">
            <div className="flex items-center rounded-full hover:bg-gray-75">
              <UserProfileIcon profilePictureUrl={user?.profilePictureUrl} />
              <MobileSettingsMenu handleOptionSelect={handleOptionSelect} />
            </div>
          </div>
        ) : (
          <UserProfileIcon profilePictureUrl={user?.profilePictureUrl} />
        )}
      </div>
      {openReportIssue && (
        <ReportIssue isOpen={openReportIssue} onCloseHandler={handleReportIssueClose} />
      )}
    </div>
  )
}

export default MobileNavBar
