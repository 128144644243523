import { gql } from '@apollo/client'

export const GET_COST_CENTERS = gql`
  query GetCostCentersForAutocomplete {
    getAllActiveCostCenters {
      id
      name
      code
    }
  }
`
