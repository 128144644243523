import { useState, useEffect, useCallback } from 'react'

type DeviceType = 'mobile' | 'tablet' | 'desktop'

type UseDeviceDetectionReturnType = {
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
  deviceType: DeviceType
}

const useDeviceDetection = (): UseDeviceDetectionReturnType => {
  const detectDevice = useCallback((): UseDeviceDetectionReturnType => {
    const userAgent = navigator.userAgent.toLowerCase()
    const width = window.innerWidth

    const isMobile =
      /iphone|ipod|android|blackberry|windows phone/g.test(userAgent) || width < 768
    const isTablet =
      /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent) ||
      (width >= 768 && width < 1024)
    const isDesktop = !isMobile && !isTablet

    let deviceType: DeviceType = 'desktop'

    if (isMobile) {
      deviceType = 'mobile'
    } else if (isTablet) deviceType = 'tablet'

    return { isMobile, isTablet, isDesktop, deviceType }
  }, [])

  const [deviceInfo, setDeviceInfo] = useState<UseDeviceDetectionReturnType>(() => {
    if (typeof window === 'undefined') {
      return {
        isMobile: false,
        isTablet: false,
        isDesktop: true,
        deviceType: 'desktop',
      }
    }

    // Initial detection
    return detectDevice()
  })

  useEffect(() => {
    const handleResize = () => {
      setDeviceInfo(detectDevice())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [detectDevice])

  return deviceInfo
}

export default useDeviceDetection
