import {
  FC,
  PropsWithChildren as PWC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { twMerge } from 'tailwind-merge'
import { useRouter } from 'next/navigation'
import { gql } from '@apollo/client'
import { isNil } from 'ramda'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Button from '~/src/components/generic/Button'

import SettingsMenu from '~/src/components/shared/NavBar/components/SettingsMenu'
import { ActionEnum } from '~/src/components/shared/NavBar/types'
import UserProfileIcon from '~/src/components/shared/UserProfileIcon'
import NotificationMenu from '~/src/components/shared/NavBar/components/NotificationMenu'
import { getNotificationTimeFloor } from '~/src/components/shared/features/helpers'
import useGetNotificationsForUser from '~/src/components/shared/Notification/hooks/useGetNotificationsForUser'
import useSubscribeNotifications from '~/src/components/shared/Notification/hooks/useSubscribeNotifications'
import DynamicLogo from '~/src/components/shared/NavBar/components/DynamicLogo'

import { isRadiusUser } from '~/helpers/auth'

import { useAuthenticatedUser } from '~/hooks/useAuthentication'

import ReportIssue from '~/src/components/ReportIssue'
import { MixpanelEvent, resetMixpanel, track } from '~/clients/mixpanelClient'

//TODO: move it to separate file
export const LOGOUT = gql`
  mutation Logout {
    logout {
      id
    }
  }
`
type Props = {
  onOpenSettings: () => void
  title?: string
  logo?: string
  className?: string
}
const NavBar: FC<PWC<Props>> = ({
  title,
  logo,
  children,
  className,
  onOpenSettings,
}) => {
  const [openReportIssue, setOpenReportIssue] = useState(false)
  const [user, logout] = useAuthenticatedUser()
  useSubscribeNotifications()

  const { showUserConfig } = useFlags()
  const shouldShowUserConfig = showUserConfig && isRadiusUser(user)
  const router = useRouter()
  const { showNotifications } = useFlags()

  useEffect(() => {
    if (isNil(user.settings.updatedAt)) {
      onOpenSettings?.()
    }
  }, [user, onOpenSettings])

  const handleReportIssueClose = useCallback(() => {
    setOpenReportIssue(!openReportIssue)
  }, [openReportIssue])

  const handleOptionSelect = useCallback(
    (action: ActionEnum) => {
      switch (action) {
        case ActionEnum.ReportIssue:
          setOpenReportIssue(!openReportIssue)
          break
        case ActionEnum.Settings:
          onOpenSettings?.()
          break
        case ActionEnum.Logout:
          logout({
            onCompleted: (data) => {
              track(MixpanelEvent.UserLogout, { userId: data.logout.id })
              resetMixpanel()
              router.push('/login')
            },
          })
          break
        default:
          break
      }
    },
    [logout, onOpenSettings, openReportIssue, router]
  )

  const timeFloor = useMemo(() => getNotificationTimeFloor(), [])
  const shouldQueryForNotifications = showNotifications && isRadiusUser(user)

  useGetNotificationsForUser(timeFloor, shouldQueryForNotifications)

  return (
    <div
      className={twMerge(
        'flex min-h-[60px] w-full items-center justify-between border-b border-solid border-gray-75 bg-white px-10',
        className
      )}
      data-testid="navbar"
    >
      <div className="flex items-center space-x-2 uppercase tracking-wide">
        {logo && <DynamicLogo imgName={logo} />}
        {title && !logo && <div className="text-gray-800">{title}</div>}
        {children}
      </div>

      <div className="flex flex-row space-x-12">
        <Button
          variant="link"
          className="text-sm font-medium text-gray-800 hover:bg-gray-50"
          onClick={() => setOpenReportIssue(!openReportIssue)}
        >
          Report an issue
        </Button>

        {shouldShowUserConfig ? (
          <div className="flex items-center gap-4">
            {showNotifications && (
              <div className="w-11 rounded hover:bg-gray-50 active:border-gray-75">
                <NotificationMenu onOpenSettings={onOpenSettings} />
              </div>
            )}
            <div className="flex items-center rounded-full hover:bg-gray-75">
              <UserProfileIcon profilePictureUrl={user?.profilePictureUrl} />

              <SettingsMenu handleOptionSelect={handleOptionSelect} />
            </div>
          </div>
        ) : (
          <UserProfileIcon profilePictureUrl={user?.profilePictureUrl} />
        )}
      </div>
      {openReportIssue && (
        <ReportIssue isOpen={openReportIssue} onCloseHandler={handleReportIssueClose} />
      )}
    </div>
  )
}

export default NavBar
