import { Size, Variant } from '~/src/components/generic/Button/types'

export const variantClassesV2: Record<Variant, string> = {
  primary:
    'bg-gray-500 border border-gray-500 text-white hover:bg-gray-900 hover:border-gray-900 active:border-gray-100 disabled:text-gray-25 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:border-none',
  secondary:
    'hover:bg-gray-25 hover:text-gray-900 hover:border-gray-900 active:border-gray-100 text-gray-700 disabled:text-gray-300 disabled:cursor-not-allowed text-gray-500 border border-gray-500',
  link: 'text-xs text-blue-600 underline tracking-wide underline-offset-2 hover:bg-blue-50 p-1 disabled:text-gray-300 disabled:cursor-not-allowed font-medium',
}

export const variantClasses: Record<Variant, string> = {
  primary:
    'bg-blue-500 text-white hover:bg-blue-400 active:border-blue-100 active:bg-blue-600 disabled:text-white disabled:cursor-not-allowed disabled:bg-gray-200 disabled:border-none',
  secondary:
    'hover:bg-gray-50 active:ring-gray-75 active:ring-2 text-gray-700 disabled:text-gray-300 disabled:cursor-not-allowed',
  link: 'text-xs text-blue-600 underline tracking-wide underline-offset-2 hover:bg-blue-50 p-1 disabled:text-gray-300 disabled:cursor-not-allowed font-medium',
}

export const sizeClasses: Record<Size, string> = {
  md: 'text-xs tracking-wide font-medium',
  lg: 'text-base shadow',
}
