import { FC, Fragment } from 'react'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'

import CaretDownIcon from '~/src/components/generic/PhosphorIcons/CaretDownIcon'
import GearSixIcon from '~/src/components/generic/PhosphorIcons/GearSixIcon'
import SignOutIcon from '~/src/components/generic/PhosphorIcons/SignOutIcon'
import FileXIcon from '~/src/components/generic/PhosphorIcons/FileXIcon'

import { ActionEnum, ItemMenu } from '~/src/components/shared/NavBar/types'

import colors from '~/config/colorsScheme'

const OPTIONS: Array<ItemMenu> = [
  {
    iconComponent: FileXIcon,
    label: 'Report an issue',
    action: ActionEnum.ReportIssue,
  },
  {
    iconComponent: GearSixIcon,
    label: 'Settings',
    action: ActionEnum.Settings,
  },
  {
    iconComponent: SignOutIcon,
    label: 'Log out',
    action: ActionEnum.Logout,
  },
]

type Props = {
  handleOptionSelect: (option: ActionEnum) => void
}

const MobileSettingsMenu: FC<Props> = ({ handleOptionSelect }) => {
  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <MenuButton className="w-8 pl-2 pt-2" aria-label="Open settings">
          <CaretDownIcon size={18} weight="fill" color={colors.gray[600]} />
        </MenuButton>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <MenuItems className="absolute right-0 z-[999] mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
            <div className="px-1 py-1">
              {OPTIONS.map(({ iconComponent: Icon, label, action }, idx) => (
                <MenuItem key={idx}>
                  {({ focus }) => (
                    <button
                      className={twMerge(
                        focus ? 'bg-blue-50' : 'text-gray-900',
                        'flex w-full items-center space-x-2 rounded-md p-2 text-sm font-medium'
                      )}
                      onClick={() => handleOptionSelect(action)}
                    >
                      <Icon size={24} color={colors.gray[600]} />
                      <div>{label}</div>
                    </button>
                  )}
                </MenuItem>
              ))}
            </div>
          </MenuItems>
        </Transition>
      </Menu>
    </>
  )
}

export default MobileSettingsMenu
