import { getWeekDateRange } from '~/src/components/Operational/DispatchLoadDashboard/helpers'

import {
  LegStatus,
  LoadStatus,
  SortInput,
  TractorStatus,
} from '~/__generated_types__/globalTypes'

import { StopStatus } from '~/src/components/Operational/LoadBoardDashboard/types'

export const ORDER_STATUSES: Array<LoadStatus> = [
  LoadStatus.Available,
  LoadStatus.Planned,
  LoadStatus.Dispatched,
  LoadStatus.Started,
  LoadStatus.Suspended,
  LoadStatus.Imported,
  LoadStatus.Completed,
  LoadStatus.Cancelled,
]

export const ASSIGNABLE_LEG_STATUS = [
  LegStatus.Planned,
  LegStatus.Available,
  LegStatus.Dispatched,
]

export enum SortPlannedStartTime {
  PlannedStartTimeAsc = 'PLANNED_START_TIME_ASC',
  PlannedStartTimeDesc = 'PLANNED_START_TIME_DESC',
}

export const LIMIT = 25

export const EMPTY_FILTER = {
  costCenterIdIn: null,
  limit: LIMIT,
  offset: 0,
  statusIn: [LoadStatus.Available, LoadStatus.Planned],
  plannedStartTimeDateRange: [
    getWeekDateRange().start ?? '',
    getWeekDateRange().end ?? '',
  ],
  sortByPlannedStartTime: SortInput.Asc,
}

export const STOP_STATUS_COLOR_MAP: Map<StopStatus, string> = new Map([
  [StopStatus.Completed, 'bg-blue-50 text-blue-800'],
  [StopStatus.AtLocation, 'bg-green-50 text-green-800'],
  [StopStatus.InTransit, 'bg-green-50 text-green-800'],
  [StopStatus.Planned, 'bg-yellow-50 text-yellow-900'],
])

export const ERROR_DELAY_MOBILE = 10000
export const SUCCESS_DELAY_MOBILE = 1000

const InvalidTMWVersionIDScos = 'InvalidTMWVersionIDScos'
const InvalidTMWVersionIDClient = 'InvalidTMWVersionIDClient'

export const TMW_VERSION_ID_ERRORS = [
  InvalidTMWVersionIDScos,
  InvalidTMWVersionIDClient,
]

export const TRACTOR_TYPE: Map<string, string> = new Map([
  ['TA Tractor', 'TA-TRACT'],
  ['TA Conv Sleeper Trct', 'TA-SLEEP'],
  ['SA Tractor', 'SA-TRACT'],
  ['UNKNOWN', 'UNKNOWN'],
])

export const TRACTOR_TYPE_TO_COLOR: Map<string, string> = new Map([
  ['TA-TRACT', 'bg-green-50 text-green-800'],
  ['TA-SLEEP', 'bg-darkPurple-50 text-darkPurple-800'],
  ['SA-TRACT', 'bg-green-50 text-green-800'],
  ['UNKNOWN', 'bg-red-50 text-red-800'],
  ['OTHER', 'bg-red-50 text-red-800'],
])

export const TRACTOR_TYPE_TO_TOOLTIP: Map<string, string> = new Map([
  ['TA-TRACT', 'Tandem axle tractor'],
  ['TA-SLEEP', 'Tandem axle sleeper tractor'],
  ['SA-TRACT', 'Single axle tractor'],
  ['UNKNOWN', 'Unknown type'],
  ['OTHER', 'Uncommon tractor type'],
])

export const UNAVAILABLE_TRACTOR_STATUSES = [TractorStatus.Out, TractorStatus.Vac]

export const SEARCH_LIMIT = 5
export const TRACKING_SOURCE = 'mobile dispatch'
