import { FC } from 'react'
import Image from 'next/image'

const LogoMap: Record<
  string,
  {
    alt: string
    path: string
    size: {
      width: number
      height: number
    }
  }
> = {
  rydershare: {
    alt: 'Rydershare Logo',
    path: '/images/RydershareLogo.svg',
    size: {
      width: 132,
      height: 19,
    },
  },
  loadBoard: {
    alt: 'Load Board Logo',
    path: '/images/LoadBoardLogo.svg',
    size: {
      width: 190,
      height: 22,
    },
  },
  driverVisibility: {
    alt: 'Driver Visibility Logo',
    path: '/images/DriverVisibilityLogo.svg',
    size: {
      width: 223,
      height: 22,
    },
  },
  shipments: {
    alt: 'Rydershare Logo',
    path: '/images/RydershareLogo.svg',
    size: {
      width: 132,
      height: 19,
    },
  },
}

type Props = {
  imgName: string
}

const DynamicLogo: FC<Props> = ({ imgName }) => {
  const image = LogoMap[imgName]

  return <Image src={image.path} alt={image.alt} {...image.size} />
}

export default DynamicLogo
