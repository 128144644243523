import { FC, useCallback, useState } from 'react'
import { useQuery } from '@apollo/client'

import SearchableDropdownV2 from '~/src/components/generic/SearchableDropdownV2'

import {
  GetCostCentersForAutocompleteQuery,
  GetCostCentersForAutocompleteQueryVariables,
} from '~/src/components/shared/CostCenterAutocomplete/__generated_types__/queries.gql'
import { GET_COST_CENTERS } from '~/src/components/shared/CostCenterAutocomplete/queries'
import { CostCenter } from '~/src/components/shared/CostCenterAutocomplete/types'

const CostCenterAutocomplete: FC<{
  className?: string
  inputClassName?: string
  fuseThreshold?: number
  onSelect: (costCenter: CostCenter) => void
  placeholder?: string
}> = ({ className, onSelect, inputClassName, fuseThreshold, placeholder }) => {
  const [searchValue, setSearchValue] = useState('')

  const { data } = useQuery<
    GetCostCentersForAutocompleteQuery,
    GetCostCentersForAutocompleteQueryVariables
  >(GET_COST_CENTERS)

  const costCenters = data?.getAllActiveCostCenters ?? []

  const handleSelect = useCallback(
    (costCenter: CostCenter) => {
      onSelect(costCenter)
      setSearchValue('')
    },
    [onSelect]
  )

  return (
    <SearchableDropdownV2
      className={className}
      placeholder={placeholder || 'Add cost centers'}
      value={searchValue || ''}
      fuseThreshold={fuseThreshold}
      maxNumOptions={100}
      options={costCenters}
      searchKeys={['name']}
      renderOption={({ name }) => (
        <div className="py-1.5">
          <div className="text-xs font-medium">{name}</div>
        </div>
      )}
      inputClassName={inputClassName}
      onSelect={handleSelect}
      onChange={setSearchValue}
    />
  )
}

export default CostCenterAutocomplete
