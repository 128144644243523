import { FC } from 'react'
import Image from 'next/image'

import UserIcon from '~/src/components/generic/PhosphorIcons/UserIcon'

import colors from '~/config/colorsScheme'

type Props = {
  profilePictureUrl?: string | null
}

const UserProfileIcon: FC<Props> = ({ profilePictureUrl }) => {
  return (
    <div className="relative my-0.5 ml-0.5 flex h-8 w-8 items-center justify-center overflow-hidden rounded-full bg-gray-75 text-white">
      {profilePictureUrl ? (
        <Image src={profilePictureUrl} alt="User profile picture" fill />
      ) : (
        <UserIcon size={20} color={colors.gray[500]} aria-label="User profile icon" />
      )}
    </div>
  )
}

export default UserProfileIcon
