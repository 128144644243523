import { FC, useState, useCallback, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'
import { useMutation } from '@apollo/client'
import { eqBy, includes, isEmpty, map, prop, symmetricDifferenceWith } from 'ramda'

import SearchPill from '~/src/components/generic/SearchPill'
import HeartIcon from '~/src/components/generic/PhosphorIcons/HeartIcon'
import RadioGroup from '~/src/components/generic/RadioGroup'
import { useToast } from '~/src/components/generic/Toast/hook/useToast'

import CostCenterAutocomplete from '~/src/components/shared/CostCenterAutocomplete/CostCenterAutocomplete'
import BaseDialog from '~/src/components/shared/BaseDialog'
import { MAX_NUMBER_COST_CENTERS } from '~/src/components/shared/features/constants'

import { useAuthenticatedUser } from '~/hooks/useAuthentication'

import { UPDATE_USER_SETTINGS } from '~/src/components/Settings/queries'
import { MixpanelEvent, track } from '~/clients/mixpanelClient'
import { ERROR_DELAY, PAGES } from '~/src/components/Settings/constants'
import { DEFAULT_OPERATOR_HOME_PAGE } from '~/app/constants'

import {
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables,
} from '~/src/components/Settings/__generated_types__/queries.gql'
import { HomePage, UserSettingsUpdate } from '~/__generated_types__/globalTypes'

import { CostCenter } from '~/src/components/Settings/types'

type Props = {
  showHomePageSetting?: boolean
  onCloseDialog: () => void
}

const Settings: FC<Props> = ({ showHomePageSetting = true, onCloseDialog }) => {
  const [costCenters, setCostCenters] = useState<Array<CostCenter>>([])
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [user, , { loading: isCurrentUserLoading, refetch }] = useAuthenticatedUser()
  const [selectedHomePage, setSelectedHomePage] = useState<HomePage>(
    DEFAULT_OPERATOR_HOME_PAGE
  )
  const toast = useToast()

  const [updateUserSettings, { loading }] = useMutation<
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables
  >(UPDATE_USER_SETTINGS)

  useEffect(() => {
    setSelectedHomePage(user.settings.homePage ?? DEFAULT_OPERATOR_HOME_PAGE)
    setCostCenters(user.settings.favoriteCostCenters ?? [])

    if (user.settings.updatedAt === null) {
      updateUserSettings({
        variables: {
          input: {
            userId: user.id,
          },
        },
      })
    }
  }, [user, updateUserSettings])

  const handleChange = useCallback(
    (costCenter: CostCenter) => {
      if (costCenters.length >= MAX_NUMBER_COST_CENTERS) {
        setShowErrorMessage(true)
        return
      }

      if (!includes(costCenter.id, map(prop('id'), costCenters))) {
        setCostCenters([...costCenters, costCenter])
      }
    },
    [costCenters]
  )

  const removeFilter = useCallback(
    (costCenter: CostCenter) => {
      const filteredCostCenters = costCenters.filter(
        (currentCostCenter) => currentCostCenter !== costCenter
      )

      setShowErrorMessage(false)
      setCostCenters([...filteredCostCenters])
    },
    [costCenters]
  )

  const handleSave = useCallback(() => {
    if (isCurrentUserLoading) return

    const input: UserSettingsUpdate = {
      userId: user.id,
    }
    const costCentersUpdated = !isEmpty(
      symmetricDifferenceWith(
        eqBy(prop('id')),
        costCenters,
        user.settings.favoriteCostCenters
      )
    )

    if (costCentersUpdated) {
      const selectedCostCenterIds = costCenters.map((costCenter) => costCenter.id)
      input.favoriteCostCenters = selectedCostCenterIds

      track(MixpanelEvent.UserSettingsFavoriteCostCenterChanged, {
        favoriteCostCenters: selectedCostCenterIds,
      })
    }

    if (selectedHomePage !== user.settings.homePage) {
      input.homePage = selectedHomePage

      track(MixpanelEvent.UserSettingsHomePageChanged, {
        homePage: selectedHomePage,
      })
    }

    if (!input.favoriteCostCenters && !input.homePage) {
      onCloseDialog()
      return
    }

    const toastId = new Date().valueOf()
    const errorMessage =
      "Something went wrong and the changes couldn't be saved, please try again later"

    updateUserSettings({
      variables: {
        input,
      },
      onCompleted: (data) => {
        if (data.updateUserSettings.__typename === 'UserSettings') {
          refetch()
          onCloseDialog()
        } else if (
          data.updateUserSettings.__typename === 'TooManyFavoriteCostCenters'
        ) {
          setShowErrorMessage(true)
        } else {
          toast.error(errorMessage, { id: toastId, delay: ERROR_DELAY })
        }
      },
      onError: () => {
        toast.error(errorMessage, { id: toastId, delay: ERROR_DELAY })
      },
    })
  }, [
    costCenters,
    selectedHomePage,
    isCurrentUserLoading,
    user,
    toast,
    onCloseDialog,
    updateUserSettings,
    refetch,
  ])

  const infoMessage = showErrorMessage
    ? `You can select up to ${MAX_NUMBER_COST_CENTERS} favorite cost centers in Radius. Please remove one of the cost centers above before adding a new one.`
    : `Select up to ${MAX_NUMBER_COST_CENTERS} of your cost centers that will automatically pre-load and generate notifications every time you’re in Radius.`

  return (
    <BaseDialog
      isOpen={true}
      title="User Settings"
      primaryButtonText="Save"
      loading={loading}
      className="max-w-sm"
      onClose={onCloseDialog}
      onConfirm={handleSave}
    >
      <div className="space-y-4">
        <div className="space-y-1">
          <div className="space-y-2">
            <div className="text-sm font-bold">Favorite cost centers</div>
            <CostCenterAutocomplete
              fuseThreshold={0.3}
              onSelect={handleChange}
              inputClassName="h-9 rounded w-full"
            />
            <div className="flex max-h-[20rem] flex-wrap justify-start gap-3 overflow-auto align-middle">
              {costCenters.map((costCenter) => (
                <SearchPill
                  key={costCenter.id}
                  content={costCenter.name}
                  onClose={() => removeFilter(costCenter)}
                  className="rounde w-fit min-w-fit bg-gray-50"
                  ariaLabel={`Remove ${costCenter}`}
                >
                  <HeartIcon size="14" />
                </SearchPill>
              ))}
            </div>
          </div>
          <div
            className={twMerge(
              'text-xs font-medium text-gray-800',
              showErrorMessage ? 'text-red-500' : ''
            )}
          >
            {infoMessage}
          </div>
        </div>
        {showHomePageSetting && (
          <div className="space-y-2">
            <div className="text-sm font-bold">Default home page</div>
            <RadioGroup
              name="Home Page Options"
              options={PAGES}
              onSelect={setSelectedHomePage}
              value={selectedHomePage}
              optionClassName="accent-gray-600"
              className="justify-start space-x-8"
            />

            <div className="text-xs font-medium text-gray-800">
              Set your home page in Radius
            </div>
          </div>
        )}
      </div>
    </BaseDialog>
  )
}

export default Settings
